import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import ArchiveHero from 'src/components/organisms/ArchiveHero/ArchiveHero';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { sanitizeEncodedChar } from 'src/utils';
import { IMenuOpen, IPageContext } from 'src/utils/interfaces';
import styles from './ProjectArchiveTemplate.module.scss';
import Pagination from 'src/components/organisms/Pagination/Pagination';
import ProjectServiceCard from 'src/components/molecules/ProjectServiceCard/ProjectServiceCard';

export class UnconnectedProjectArchiveTemplate extends PureComponent<IPageContext> {
  render() {
    const { pageContext = {}, location } = this.props;
    const { items: services } = pageContext;
    const pageTitle = sanitizeEncodedChar(pageContext.title);

    if (typeof window !== 'undefined') {
      // console.log('[ProjectArchive]', services);
    }

    return (
      <>
        <CoreLayout
          location={location}
          title={pageTitle}
          description={pageContext.yoast_meta && pageContext.yoast_meta.yoast_wpseo_metadesc}
          data-test="template-archive"
          className={
            classNames(
              [
                styles.archive,
                {
                  [`archive__${pageContext.slug && pageContext.slug.replace('_', '-')}`]: pageContext.slug,
                },
              ]
            )
          }
        >
          <ArchiveHero
            pageContext={pageContext}
            location={location}
          />

          <Section>
            <Container fluid={false} className={styles.archiveProjects}>
              <Row>
                {services && services.sort((a: any, b: any) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                }).map((obj: any) => {
                  const { node: service } = obj;
                  return (
                    <Flex
                      key={service.id}
                      colXl={4}
                      colLg={6}
                      colMd={6}
                      className={styles.archiveProjectsProjectWrapper}
                    >
                      <ProjectServiceCard {...service}/>
                    </Flex>
                  );
                })}
              </Row>
            </Container>
          </Section>

          <Pagination location={location} totalPages={pageContext.noOfPages} currentPage={pageContext.currentPage}/>

        </CoreLayout>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ core: { isMenuOpen } }: IMenuOpen) => ({
  isMenuOpen,
});

export default connect(
  mapStateToProps,
  null,
)(UnconnectedProjectArchiveTemplate);
