import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import Button from 'src/components/molecules/Buttons/Button';
import Heading from 'src/components/shared/Heading/Heading';
import styles from './ProjectServiceCard.module.scss';

export interface IProjectServiceCardProps {
  id: string | number;
  wordpress_id: string | number;
  slug: string;
  name: string;
  count: number;
  projects: any;
  className?: string;
}

const ProjectServiceCard: FunctionComponent<Partial<IProjectServiceCardProps>> = (
  {
    name,
    className,
    count,
    slug,
  }
) => {
  const link = `/project/service/${slug}`;

  return (
    <article
      data-test="component-project-service-card"
      className={classNames(styles.projectServiceCard, className)}
    >
      <Link to={link} className={styles.projectServiceCardLink}>
        <span className="sr-only">{name}</span>
      </Link>

      <Heading priority={3} className={styles.projectServiceCardHeading}>
        {name}
      </Heading>

      <p className={styles.projectServiceCardCopy}>
        Number of projects ({count})
      </p>

      <Button
        size={'xs'}
        link={link}
        theme={'tint-psi'}
        className={styles.projectServiceCardButton}
      >
        Learn more
      </Button>
    </article>
  );
};

export default ProjectServiceCard;
